<template>
  <div>
    <div v-if="isFinished">
      <p>{{ $t('hunt.events.helpText') }}</p>

      <ButtonGroup segmented>
        <Button @click="addEvent('kill')">
          <img src="/img/markers/marker-kill.svg" />
          <span>{{ $t('hunt.events.kill') }}</span>
        </Button>

        <Button @click="addEvent('observation')">
          <img src="/img/markers/marker-observation.svg" />
          <span>{{ $t('hunt.events.observation') }}</span>
        </Button>
      </ButtonGroup>

      <InlineLoader v-if="isLoading" />

      <div v-else>
        <Table v-if="events.length > 0" bordered scrollable>
          <TableHeader>
            <TableHeaderCell :heading="$t('general.type')" />
            <TableHeaderCell :heading="$t('hunt.gameTitle')" />
            <TableHeaderCell :heading="$t('general.when')" />
            <TableHeaderCell :heading="$t('general.comment')" />
            <TableHeaderCell />
          </TableHeader>

          <TableContent>
            <TableRow
              v-for="(event, i) in events"
              :key="i"
              :class="{ 'is-dimmed': event.status === 'deleting' || event.status === 'loading' }">
              <TableCell class="is-narrow">
                <img :src="icon(event)" />
              </TableCell>

              <TableCell class="is-narrow">
                <span v-if="!event.editable">{{ event.name }}</span>

                <div
                  v-if="event.editable"
                  id="game"
                  :class="{ 'is-loading': availableGames === null }"
                  class="select is-small">
                  <select v-model="event.animalId" @change="updateGameName(event)">
                    <option value>{{ $t('hunt.events.selectSpecies') }}</option>
                    <option
                      v-for="animal in availableGames"
                      :key="animal.id"
                      :value="animal.id">
                      {{ animal.name }}
                    </option>
                  </select>
                </div>
              </TableCell>

              <TableCell class="is-narrow">
                {{ date(event.createdAt) }}
              </TableCell>

              <TableCell class="has-text-grey">
                <small v-if="!event.editable && event.note">{{ event.note }}</small>

                <div v-if="event.editable" class="field is-grouped" style="width: 100%;">
                  <div class="control is-expanded">
                    <input v-model="event.note" class="input is-small" />
                  </div>
                </div>
              </TableCell>

              <TableCell class="is-narrow has-text-centered">
                <small>
                  <Icon
                    v-if="!canEdit(event)"
                    name="icon-lock"
                    class="has-text-grey"
                    :title="$t('hunt.events.locked')" />
                </small>

                <div v-if="!event.editable && canEdit(event)">
                  <Button
                    :title="$t('general.edit')"
                    size="small"
                    @click="edit(event)">
                    <Icon name="icon-edit-2" />
                  </Button>

                  <Button
                    v-if="!event.editable"
                    type="danger"
                    size="small"
                    :class="{ 'is-loading': event.status === 'deleting' }"
                    :title="$t('general.remove')"
                    @click="confirmRemove(event)">
                    <Icon name="icon-trash" />
                  </Button>
                </div>

                <div v-else>
                  <Button
                    v-if="event.editable"
                    type="success"
                    size="small"
                    :title="$t('general.edit')"
                    @click="update(event)">
                    <Icon name="icon-save" />
                  </Button>

                  <Button
                    v-if="event.editable"
                    size="small"
                    @click="cancelEdit(event)">
                    <Icon name="icon-x" class="has-text-danger" />
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          </TableContent>
        </Table>

        <p v-else class="has-text-grey">
          {{ $t('hunt.events.noEvents') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {
  static () {
    return {
      temporary: {
        note: null
      }
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      availableGames: 'hunt/games/availableGames'
    }),

    events () {
      const events = this.$store.getters['hunt/events/events'] || []
      return events.slice().sort((a, b) => a.createdAt - b.createdAt)
    },

    isAddingEvent: {
      get () { return this.$store.getters['hunt/events/isAddingEvent'] },
      set (state) { this.$store.commit('hunt/events/isAddingEvent', state) }
    },

    eventType: {
      get () { return this.$store.getters['hunt/events/type'] },
      set (state) { this.$store.commit('hunt/events/setType', state) }
    },

    isFinished () {
      return this.hunt && this.hunt.status === 'finished'
    },

    isLoading () {
      return this.$store.getters['hunt/events/events'] === null
    }
  },

  methods: {
    async addEvent (type) {
      this.isAddingEvent = true
      this.eventType = type
    },

    async confirmRemove (event) {
      try {
        const response = await this.$dialog.confirm({
          title: this.$t('general.remove'),
          message: this.$t('hunt.events.removeConfirmMessage', {
            type: this.$t(`hunt.events.${event.type}`),
            date: this.date(event.date)
          }),
          cancel: this.$t('general.cancel'),
          ok: this.$t('general.remove')
        })

        if (response.ok) {
          await this.$store.dispatch('hunt/events/delete', {
            huntId: this.hunt.id,
            event: event
          })
        }
      } catch (error) {
        this.$notification.danger(this.$i18n.t('hunt.events.removeFailedErrorMessage'))
        console.error(error)
      }
    },

    async edit (event) {
      this.events.forEach(evt => {
        if (evt.editable) this.cancelEdit(evt)
      })

      this.temporary.note = event.note
      this.temporary.name = event.name
      this.temporary.animalId = event.animalId

      event.editable = true
    },

    cancelEdit (event) {
      event.editable = false

      event.note = this.temporary.note
      event.name = this.temporary.name
      event.animalId = this.temporary.animalId

      this.temporary.note = null
      this.temporary.name = ''
      this.temporary.animalId = null
    },

    updateGameName (event) {
      event.name = this.availableGames && this.availableGames.filter(animal => Number(animal.id) === Number(event.animalId))[0].name
    },

    async update (event) {
      event.editable = false
      event.status = 'loading'

      try {
        await this.$store.dispatch('hunt/events/update', {
          huntId: this.hunt.id,
          event: event
        })
      } catch (error) {
        this.$notification.danger(this.$i18n.t('hunt.events.updateFailedErrorMessage'))
        console.error(error)
      }

      event.status = ''
    },

    setSelected (event) {
      return this.$store.commit('hunt/events/select', event)
    },

    date (date) {
      return this.hunt.isMultipleDays() ? dayjs(date).format('D MMM HH:mm') : dayjs(date).format('HH:mm')
    },

    async getFullName (userId) {
      let name

      try {
        name = await this.$store.dispatch('user/getFullNameById', Number(userId))
      } catch (error) {
        name = ''
      }

      return name
    },

    icon (event) {
      return getMarkerTypeIconUrl(event.type)
    },

    canEdit (event) {
      const userId = this.$store.getters['auth/getUserId']
      const isAdmin = this.$store.getters['hunt/isAdmin']
      return event.createdBy === userId || isAdmin
    }
  }
}
</script>
